import React, { useEffect } from "react";
import Header from "../../../../Home/Header";
import Footer from "../../../../Home/Footer";
import file3 from "../../../../json/15.json";
import Lottie from "lottie-react";
export const OverViewMore = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <Header />
      <div className="container morediv">
        <section className="row spacer-lg pt-0 align-items-center">
          <div className="col-lg-6 col-md-6 mb-3">
            <Lottie animationData={file3} loop={true} />
          </div>
          <div className="col-lg-6 col-md-6 mb-3">
            <h2 className="chainlist-heading">Overview </h2>
            <p className="about-para">
              - No local currency or fiat currency is involved; it’s entirely
              legal as crypto tokens are considered assets like gold, silver, or
              real estate.
            </p>
            <p className="about-para">
              - Members can encash their donated crypto assets through crypto
              exchanges as per their country’s tax regulations.
            </p>
            <p className="about-para">-Membership fee: 30 USDT.</p>
            <p className="about-para">
              -Target rewards are the same across all plans, and upon reaching
              the final target, members receive permanent lifetime membership.
            </p>
            <p className="about-para">
              Members can communicate directly with their senior members and
              downline team through the dashboard.
            </p>
            <h2 className="chain-list-gradient-para">
              Permanent Membership Benefits:
            </h2>
            <p className="about-para">
              -Once a member achieves permanent membership, they will enjoy
              monthly benefits without any additional targets or criteria.
            </p>
            <p className="about-para">
              - This serves as a retirement benefit with substantial monthly
              crypto earnings.
            </p>
            <div>
              {" "}
              <a href="#" className="btn btn-small">
                Home
              </a>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
