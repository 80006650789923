import React from "react";
import { Link } from "react-router-dom";
import file11 from "../json/15.json";
import Lottie from "lottie-react";

export const OverView = () => {
  return (
    <div>
      <section className="services-area pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <h2>Overview</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="about-img text-center">
                <Lottie animationData={file11} loop={true} />
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="about-text">
                <p>
                  - The DMT Club is the world’s first and largest crypto gift
                  donation club where members from all countries can join by
                  donating a small amount of crypto assets. - Upon joining,
                  members can receive large amounts of crypto donations, hold
                  them long-term, and sell them at a good rate on crypto
                  exchanges, thus significantly improving their financial
                  status. -
                </p>
                <p>
                  - The DMT Club is the world’s first and largest crypto gift
                  donation club where members from all countries can join by
                  donating a small amount of crypto assets.
                </p>
                <p>
                  - Upon joining, members can receive large amounts of crypto
                  donations, hold them long-term, and sell them at a good rate
                  on crypto exchanges, thus significantly improving their
                  financial status.
                </p>
                <a href="overview" className="default-btn">
                  <i className="bx bx-food-menu icon-arrow before" />
                  <span className="label">Read More</span>
                  <i className="bx bx-food-menu icon-arrow after" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
