import React, { useEffect } from "react";
import Header from "../../../../Home/Header";
import Footer from "../../../../Home/Footer";
import file3 from "../../../../json/security.json";
import Lottie from "lottie-react";
export const SecurityMore = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <div>
      <Header />
      <div className="container morediv">
        <section className="row spacer-lg pt-0 align-items-center">
          <div className="col-lg-6 col-md-6 mb-3">
            <Lottie animationData={file3} loop={true} />
          </div>
          <div className="col-lg-6 col-md-6 mb-3">
            <h2 className="chainlist-heading">Security </h2>
            <p className="about-para">
              Through this platform, we can guarantee one thing to everyone who
              joins our club: We promise that no one will face losses. Everyone
              who donates and receives donations will find financial freedom,
              fulfilling their needs while maintaining mutual support. By
              holding onto our crypto assets, you can increase their value and
              sell them when necessary. We proudly state that our platform is
              designed to prevent any opportunities for scams.
            </p>
            <p className="about-para">
              We do not make any commitments to offer returns on investments,
              trading profits, or weekly/monthly profits. Our sole focus is on
              increasing the value of our tokens through the success of this
              project. Our platform ensures security by maintaining a
              peer-to-peer (P2P) donation system, where donations are
              transferred directly and immediately from your wallets to others’
              wallets, with no involvement from the club or any third party.
            </p>
            <p className="about-para">
              We have designed this platform to be free of scams and scamsters.
              With continuous developments, we aim to advance this platform by
              promoting it through reputable social platforms and increasing our
              club memberships. On behalf of our club management, we solemnly
              swear on this universal power.
            </p>
            <div>
              {" "}
              <a href="#" className="btn btn-small">
                Home
              </a>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
