import React from "react";
import { Link } from "react-router-dom";

const Benefits = () => {
  const benefitsData = [
    {
      title: "Security",
      text: "Our groundbreaking blockchain network guarantees secure and tamper-proof transactions, safeguarding the integrity of every interaction.",
      icon: "🔒",
      link: "/security",
    },
    {
      title: "Transparency",
      text: "With blockchain technology, all transactions are recorded transparently, eliminating the potential for manipulation and ensuring accountability.",
      icon: "👁️",
      link: "/transparency",
    },
    {
      title: "Efficiency",
      text: "Smart contracts streamline processes and automate tasks, reducing costs and enhancing operational efficiency.",
      icon: "⚙️",
    },
    {
      title: "Accessibility",
      text: "Our network fosters global participation, making advanced blockchain solutions accessible to users worldwide and promoting inclusivity.",
      icon: "🌍",
    },
    {
      title: "Customization",
      text: "Customize platform rules and features to suit your specific needs, creating a tailored and unique experience within our blockchain network.",
      icon: "🛠️",
    },
    {
      title: "Trustworthiness",
      text: "Decentralized systems and smart contracts guarantee fair and unbiased outcomes, building trust in every transaction.",
      icon: "🤝",
    },
  ];

  return (
    <div className="benefits-section container text-centertext-white mt-5">
      <div className="section-title">
        <h2>Benifits Offer.</h2>
      </div>
      <div className="row">
        {benefitsData.map((benefit, index) => (
          <div key={index} className="col-md-4 col-sm-6 mb-4">
            <div className="benefit-card h-100">
              <div className="card-body">
                <div className="benefit-icon mb-3">{benefit.icon}</div>
                <h5 className="card-title text-white">{benefit.title}</h5>
                <p className="card-text">{benefit.text}</p>
                {benefit.link && (
                  <div className="text-start">
                    <a href={benefit.link} className="text-warning">
                      View more
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Benefits;
