import { lazy, Suspense, useEffect, useState } from "react";
import { configureChains, createClient, useAccount, WagmiConfig } from "wagmi";
import "@rainbow-me/rainbowkit/styles.css";
import { bscTestnet } from "wagmi/chains";
import Index from "./jsx";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/ss.css";

import { publicProvider } from "@wagmi/core/providers/public";
import {
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  trustWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import {
  connectorsForWallets,
  darkTheme,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";

import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

import {
  userExistAction,
  walletAddressAction,
} from "./store/actions/AuthActions";
import { isUserExist } from "./helpers/getWeb3";
import HomeMain from "./HomeMain";
import { SecurityMore } from "./jsx/components/Pages/more/SecurityMore";
import { TrustMore } from "./jsx/components/Pages/more/Trustmore";
import { AboutMore } from "./jsx/components/Pages/more/AboutMore";
import { BhashMore } from "./jsx/components/Pages/more/BhashMore";
import { OverViewMore } from "./jsx/components/Pages/more/OverViewMore";
import { Aboutmore } from "./Home/Aboutmore";
import GuestLogin from "./jsx/pages/GuestLogin";

import Login from "./jsx/pages/Login";
import Otp from "./jsx/pages/Otp";
import { LoginDashBoard } from "./jsx/pages/LoginDashBoard";
import Register from "./jsx/pages/Registration";
import LevelUpgrade from "./jsx/pages/LevelUpgrade";
import { getMailData } from "./helpers/api";
import { Plan } from "./jsx/components/Pages/Plan";
import LevelPlanB from "./jsx/pages/LevelPlanB";
import LevelPlanc from "./jsx/pages/LevelPlanC";
import RegisterB from "./jsx/pages/RegisterB";
import RegisterC from "./jsx/pages/RegisterC";
import Error404 from "./jsx/pages/Error404";

const { chains, provider, webSocketProvider } = configureChains(
  [bscTestnet],
  [publicProvider()],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: `${chain.rpcUrls.default.http[0]}`,
      }),
    }),
  ]
);

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      injectedWallet({ chains }),
      metaMaskWallet({ chains }),
      trustWallet({ chains }),
      coinbaseWallet({ chains, appName: "My RainbowKit App" }),
      walletConnectWallet({ chains }),
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  // webSocketProvider,
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function App(props) {
  const dispatch = useDispatch();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const userId = userDetails?.userId;

  const memberregister = userDetails?.memberregister;
  const { address } = useAccount();

  useEffect(() => {
    dispatch(walletAddressAction(address));


  }, [address]);
  useEffect(() => {
    let formData = {
      userId: userId,

    };
    getMailData(formData).then((res) => {

      if (res?.plan1?.email_verified && res?.plan1?.paid_30_dollar) {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        userDetails.memberregister = true;
        localStorage.setItem("userDetails", JSON.stringify(userDetails));
      } else if (res?.plan3?.email_verified && res?.plan2?.paid_30_dollar) {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        userDetails.memberregister = true;
        localStorage.setItem("userDetails", JSON.stringify(userDetails));

      } else if (res?.plan3?.email_verified && res?.plan3?.paid_30_dollar) {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        userDetails.memberregister = true;
        localStorage.setItem("userDetails", JSON.stringify(userDetails));
      }
    });
  }, [userId]);

  const { search, pathname } = props.router.location;
  if (props.isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider
              chains={chains}
              modalSize="compact"
              theme={darkTheme({
                ...darkTheme.accentColors.green,
              })}
            >
              <Routes>
                <Route path="/Bhash" element={<BhashMore />} />
                <Route path="/security" element={<SecurityMore />} />
                <Route path="/transparency" element={<TrustMore />} />
                <Route path="/overView" element={<OverViewMore />} />
                <Route path="/" element={<HomeMain />} />
                <Route path="/register" element={<GuestLogin />} />
                <Route path="/login" element={<Login />} />
                <Route path="/otp" element={
                  userId ? (
                    <Otp />
                  ) : (
                    <Navigate to="/login" />
                  )
                } />

                <Route path="/LevelUpgradeA" element={
                  userId ? (
                    <LevelUpgrade />
                  ) : (
                    <Navigate to="/login" />
                  )
                } />
                <Route path="/logindashboard" element={
                  userId ? (
                    <LoginDashBoard />
                  ) : (
                    <Navigate to="/login" />
                  )
                } />
                <Route path="/registerDMT" element={
                  userId ? (
                    <Register />
                  ) : (
                    <Navigate to="/login" />
                  )
                } />
                <Route path="/registerDMTB" element={
                  userId ? (
                    <RegisterB />
                  ) : (
                    <Navigate to="/login" />
                  )
                } />
                <Route path="/registerDMTC" element={
                  userId ? (
                    <RegisterC />
                  ) : (
                    <Navigate to="/login" />
                  )
                } />

                <Route path="/plan" element={
                  userId ? (
                    <Plan />
                  ) : (
                    <Navigate to="/login" />
                  )
                } />
                <Route path="/LevelUpgradeB" element={
                  userId ? (
                    <LevelPlanB />
                  ) : (
                    <Navigate to="/login" />
                  )
                } />
                <Route path="/LevelUpgradeC" element={
                  userId ? (
                    <LevelPlanc />
                  ) : (
                    <Navigate to="/login" />
                  )
                } />

              </Routes>
              <>
                {memberregister ? (
                  <>
                    {
                      pathname === "/dashboard" ||
                      pathname === "/treeStructure" ||
                      pathname === "/help" ||
                      pathname === "/levelIncome" ||
                      pathname === "/levelSponser" ||
                      pathname === "/roiBonus" ||
                      pathname === "/partner" ||
                      pathname === "/myteam" ||
                      pathname === "/levelReport" ||
                      pathname === "/stakingBonus" ||
                      pathname === "/Level" ||
                      pathname === "/reward" ||
                      pathname === "/reward&Recognition" ||
                      pathname === "/claimBonus" ? (
                      <Index />
                    ) : null}
                  </>
                ) :
                  null}
              </>

            </RainbowKitProvider>
          </WagmiConfig>
        </Suspense>
      </div>
    );
  }
}

export default withRouter(App);
