import React, { useEffect, useState } from "react";
import { FaCoins } from "react-icons/fa";
import { AiOutlineTeam } from "react-icons/ai";
import { GiTakeMyMoney, GiGrowth, GiLevelThreeAdvanced } from "react-icons/gi";
import { MdOutlineDirectionsWalk } from "react-icons/md";
import { BsFillCalendarDateFill } from "react-icons/bs";

import moment from "moment/moment";
import "aos/dist/aos.css";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { IoCopyOutline } from "react-icons/io5"; // Import both filled and outline icons
import { CopyToClipboard } from "react-copy-to-clipboard";
import { GiConfirmed } from "react-icons/gi";
import {
  detailByEmail,
  getDashBoardDetails,
  getRewardTimer,
} from "../../../helpers/api";
import { Loader } from "./Loader";
import CountdownTimer from "../../pages/CountDownTimer";
import { useSelector } from "react-redux";

const Home = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const { userToken } = useSelector((action) => {
    return action.auth;
  });
  const userId = userDetails?.userId;
  const [timertimeStamp, setTimerTimestamp] = useState("");
  const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
  const walletAddress = walletDetails?.wallet;
  const [isCopied, setIsCopied] = useState(false);
  const copyReferral = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  const copyWallet = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  const [loader, setLoader] = useState(true);
  const [userData, setUserData] = useState();
  const [userDashData, setUserDashData] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [with1, setRefreshWith1] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    if (walletAddress) {
      const formData = {
        packageId: userToken?.value,
        wallet_address: walletAddress,
      };   
      getDashBoardDetails(formData).then((res) => {
        setUserDashData(res);
      });
    }

    const timeoutId = setTimeout(() => {

      const formData = {
        packageId: userToken?.value,
        userId: userId,
      };
     



      detailByEmail(formData).then((res) => {
    
        localStorage.setItem(
          "walletDetails",
          JSON.stringify({
            wallet: res?.data?.user,
          })
        );
        setLoader(false);
        setUserData(res?.data);
      });
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [walletAddress,userId, refresh, userToken]);
  useEffect(() => {
    const fetchTimer = () => {
      let formData = { userId: userId };
      getRewardTimer(formData)
        .then((res) => {
          setTimerTimestamp(res?.date || 0);
        })
        .catch((err) => {
          console.error("Error fetching timer:", err);
        });
    };

    fetchTimer();

    const interval = setInterval(fetchTimer, 10000); // Call every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [userId]);


  return (
    <>
      <div className="row mt-4 ">
        {loader ? (
          <Loader />
        ) : (
          <>
            <div className="">
              <div className="widget-stat bg-success card-id-main">
                <div className="card-body ">
                  <h3>
                    <div>Reward Criteria Remaining Time</div>
                  </h3>
                  <p className="text_gray">
                    fullfill reward criteria within 15 Days membership joining
                  </p>

                  <div className="deal-of-the-day-content mb-3 mt-0 d-flex justify-content-center align-items-center">
                    <CountdownTimer timestamp={timertimeStamp} />
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="widget-stat bg-success card-id-main">
                <div className="card-body ">
                  <div className="row">
                    <div className="col-md-4">
                      <div className=" py-2">
                        <h2 className="heading  text-black">Referral Code</h2>
                      </div>
                    </div>
                    <div className="col-md-4"> </div>

                    <div className="col-md-4">
                      <div className="input-group">
                        <input
                          id="copy"
                          type="text"
                          className="form-control custom_inpt_form"
                          value={`https://dmtclubapp.com/register?user_id=${userData?.userId}`}
                          readOnly
                        />

                        <CopyToClipboard
                          text={`https://dmtclubapp.com/register?user_id=${userData?.userId}`}
                          onCopy={copyReferral}
                        >
                          <button
                            className="banner-btn btn-refral"
                            type="button"
                            style={{
                              background: "#002400",
                              padding: "8px 14px",
                              color: "#fff",
                            }}
                          >
                            {isCopied ? (
                              <GiConfirmed size={25} />
                            ) : (
                              <IoCopyOutline size={25} />
                            )}
                          </button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-4  col-lg-4 col-sm-6">
                  <div className="widget-stat bg-success card-id-main">
                    <div className="custom-padding">
                      <div className="media">
                        <span className="me-3 card-id">
                          <i className="flaticon-381-calendar-1 "></i>
                        </span>
                        <div className="media-body text-black  ">
                          <p className="mb-1 "> User ID</p>
                          {!isVisible ? (
                            <h4 className=" text-black">
                              {userData?.userId}
                            </h4>
                          ) : (
                            "*********"
                          )}

                          <div
                            onClick={toggleVisibility}
                            className=" mt-2 "
                            style={{ cursor: "pointer" }}
                          >
                            {isVisible ? (
                              <MdVisibilityOff size={20} />
                            ) : (
                              <MdVisibility size={20} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4  col-lg-4 col-sm-6">
                  <div className="widget-stat card-date-main bg-success">
                    <div className="custom-padding">
                      <div className="media">
                        <span className="me-3 card-date">
                          <BsFillCalendarDateFill />
                        </span>
                        <div className="media-body text-black ">
                          <p className="mb-1">My Joining Date & Time</p>
                          <h4 className=" text-black">
                            {moment(userData?.createdAt).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4  col-lg-4 col-sm-6">
                  <div className="widget-stat card-direct-main bg-success">
                    <div className="custom-padding">
                      <div className="media">
                        <span className="me-3 card-direct">
                          <MdOutlineDirectionsWalk />
                        </span>
                        <div className="media-body text-black">
                          <p className="mb-1">My Directs</p>
                          <h4 className=" text-black">
                            {" "}
                            {userData?.direct_member
                              ? userData?.direct_member
                              : 0}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 
                <div className="col-xl-4  col-lg-4 col-sm-6">
                  <div className="widget-stat card-team-main bg-success">
                    <div className="custom-padding">
                      <div className="media">
                        <span className="me-3 card-team">
                          <AiOutlineTeam size={24} />
                        </span>
                        <div className="media-body text-black">
                          <p className="mb-1">My Total Team</p>
                          <h4 className=" text-black">
                            {" "}
                            {userDashData?.myTotalTeam
                              ? userDashData?.myTotalTeam
                              : 0}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
     
                <div className="col-xl-4  col-lg-4 col-sm-6  mt-1">
                  <div className="widget-stat card-teamBusiness-main bg-success">
                    <div className="custom-padding">
                      <div className="media">
                        <span className="me-3 card-teamBusiness">
                          <GiTakeMyMoney />
                        </span>
                        <div className="media-body text-black ">
                          <p className="mb-1">My Total Team Business ($)</p>
                          <h4 className=" text-black">
                            $ {userDashData?.myTotalTeamBusiness}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="col-xl-4  col-lg-4 col-sm-6">
                  <div className="widget-stat card-level-main  bg-success">
                    <div className="custom-padding">
                      <div className="media ai-icon">
                        <span className="me-3 card-level">
                          <GiLevelThreeAdvanced />
                        </span>
                        <div className="media-body text-black">
                          <p className="mb-1 ">My Level Bonus ($)</p>
                          <h4 className=" text-black">
                            ${" "}
                            {userDashData?.myLevelBonus
                              ? userDashData?.myLevelBonus
                              : 0}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4  col-lg-4 col-sm-6">
                  <div className="widget-stat card-staking-main  bg-success">
                    <div className="custom-padding">
                      <div className="media">
                        <span className="me-3 card-staking">
                          <FaCoins />
                        </span>
                        <div className="media-body text-black">
                          <p className="mb-1">My Donation ($)</p>
                          <h4 className=" text-black">$ {userData?.stake_amount?userData?.stake_amount:0}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-64col-sm-6 mt-2">
                  <div
                    className="widget-stat card bg-success"
                  //style={{ background: "#7445cc" }}
                  >
                    <div className="custom-padding">
                      <div className="media">
                        <span className="me-3  card-teamBusiness">
                          <GiGrowth />
                        </span>
                        <div className="media-body text-black">
                          <p className="">Total Income </p>
                          <h4 className=" text-black">
                            ${" "}
                            {userData?.totalIncome
                              ? userData?.totalIncome
                              : 0}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-64col-sm-6 mt-2">
                  <div
                    className="widget-stat card bg-success"
                  //style={{ background: "#7445cc" }}
                  >
                    <div className="custom-padding">
                      <div className="media">
                        <span className="me-3  card-teamBusiness">
                          <GiGrowth />
                        </span>
                        <div className="media-body text-black">
                          <p className="">My Rewards Bonus </p>
                          <h4 className=" text-black">
                            ${" "}
                            0
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-64col-sm-6 mt-2">
                  <div
                    className="widget-stat card bg-success"
                  //style={{ background: "#7445cc" }}
                  >
                    <div className="custom-padding">
                      <div className="media">
                        <span className="me-3  card-teamBusiness">
                          <GiGrowth />
                        </span>
                        <div className="media-body text-black">
                          <p className="">WalletAddress</p>
                          <h4 className=" text-black">
                            {walletAddress?.slice(0, 6)}...
                            {walletAddress?.slice(-6)}
                            <CopyToClipboard
                              text={`${walletAddress}`}
                              onCopy={copyWallet}
                            >
                              {isCopied ? (
                                <GiConfirmed size={25} />
                              ) : (
                                <IoCopyOutline size={25} />
                              )}
                            </CopyToClipboard>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </>
        )}
      </div>
    </>
  );
};
export default Home;
