import { CgProfile } from "react-icons/cg";
import React, { useEffect, useRef, useState } from "react";
import logo from "../../Asset 2.png";
import video1 from "./logovideo.mp4";
import { Link, useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { GiConfirmed } from "react-icons/gi";
import { IoCopyOutline } from "react-icons/io5";
import Marquee from "react-fast-marquee";
import { getAllUserId } from "../../helpers/api";

export const LoginDashBoard = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const email = userDetails?.email;
  const userId = userDetails?.userId;
  const popoverRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [alluserId, setUserId] = useState("");

  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);
  const copyReferral = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  const handleLogout = () => {
    localStorage.removeItem("userDetails"); // Clear user details from local storage
    navigate("/login");
  };

  useEffect(() => {

    getAllUserId(email).then((res) => [

      setUserId(res)

    ])
    
    // Initialize the popover using Bootstrap's JavaScript API
    const popover = new window.bootstrap.Popover(popoverRef.current, {
      container: "body",
      html: true,
      placement: "bottom",
      content: `
        <div class="popover-body">
          <div class="dropdown-item">${email}</div>
          <a href="#" class="dropdown-item logout-btn">Logout</a>
        </div>
      `,
    });

    // Handle the click event for the logout button inside the popover
    document.addEventListener("click", (e) => {
      if (e.target.classList.contains("logout-btn")) {
        handleLogout();
      }
    });

    return () => {
      popover.dispose();
    };


  }, [email]);
  const handleOptionChange = (event) => {
    const { value, selectedOptions } = event.target;
    setSelectedOption(value);
    localStorage.setItem(
      "userDetails",
      JSON.stringify({
        userId: value,
        email: email,
      })
    );

  };

  return (
    <div>
      <div className="navbar-area">

        <div className="">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light d-flex justify-content-between">
              <Link to="/" className="logo">
                <img
                  src={logo}
                  alt=""
                  className="dash-logo"
                  // style={{ height: "55px" }}

                
                />
              </Link>
              <div className="">
                <span className="me-2 ms-2">

                  <select
                    // defaultValue={"option"}
                    className="btn-xxs btn bg-warning text-white"
                    value={selectedOption}
                    onChange={(e) => {
                      handleOptionChange(e);
                    }}
                  >
                    {alluserId&& alluserId?.map((res, i) => {

                      return (

                        <option value={res?.userId} name={res?.userId}>
                       {res?.userId}
                        </option>
                      )

                    })}


                  </select>

                </span>
                <span
                  ref={popoverRef}

                  data-bs-container="body"
                  data-bs-toggle="popover"
                  data-bs-placement="bottom"
                  data-bs-content="Bottom popover"
                >
                  <CgProfile size={35} color="#fff" />
                </span>


              </div>

            </nav>
          </div>
        </div>
      </div>
      <div className="Container">
        <div className="refralcard">
          <div className="ps-3 pe-3 pt-1 ">
            <Marquee>
              <h4 className=" text-warning pb-3">
                DMT Club - Blockchain-Powered Gift Distribution.
              </h4>
            </Marquee>
            <div className="row">
              <div className="col-md-4">
                <div className=" py-2">
                  <h4 className="heading  text-black">
                    Please Copy and Save UserID for further Login
                  </h4>
                </div>
              </div>
              <div className="col-md-4"> </div>

              <div className="col-md-4">
                <div className="input-group  mb-3">
                  <input
                    id="copy"
                    type="text"
                    className="form-control custom_inpt_form"
                    value={`${userId}`}
                    readOnly
                  />

                  <CopyToClipboard text={`${userId}`} onCopy={copyReferral}>
                    <button
                      className="banner-btn btn-refral"
                      type="button"
                      style={{
                        background: "#002400",
                        padding: "8px 14px",
                        color: "#fff",
                      }}
                    >
                      {isCopied ? (
                        <GiConfirmed size={25} />
                      ) : (
                        <IoCopyOutline size={25} />
                      )}
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <section className="about-area bg-image">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="ratio ratio-1x1">
                    <video src={video1} loop muted autoPlay></video>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-7 col-md-12">
              <div className="about-text">
                <span className="sub-title">About Us</span>
                <h2>
                  A Group with a Common Passion: We are the Dream Makers Team
                  Club
                </h2>
                <p>
                  The DMT Club is the world’s first and largest crypto gift
                  donation club
                </p>
                <h6>Permanent Membership Benefits:</h6>
                <ul className="features-text">
                  <li className=" mt-4">
                    <span>
                      <i className="bx bx-check-double"></i> Once a member
                      achieves permanent membership, they will enjoy monthly
                      benefits without any additional targets or criteria.
                    </span>
                  </li>
                  <li className=" mt-4">
                    <span>
                      <i className="bx bx-check-double "></i> This serves as a
                      retirement benefit with substantial monthly crypto
                      earnings.
                    </span>
                  </li>
                  <li className=" mt-4">
                    <span>
                      <i className="bx bx-check-double "></i> . Our platform
                      ensures security by maintaining a peer-to-peer (P2P)
                      donation system
                    </span>
                  </li>
                </ul>

                <div className="mt-5">
                  <Link
                    to='/plan'
                    className="default-btn mt-0"
                  >
                    <i className="bx bx-right-arrow-alt icon-arrow before" />
                    <span className="label">


                      Plan
                    </span>
                    <i className="bx bx-right-arrow-alt icon-arrow after" />
                  </Link>
                </div>
              </div>
            </div>

            <div className="container video mt-5 mb-5">
              <div className="card">
                <div className="card-body">
                  <div className="ratio ratio-21x9 mb-5">
                    <iframe
                      width="833"
                      height="820"
                      src="https://www.youtube.com/embed/VP525rw7tHA"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
