import React, { useState } from "react";
import { forwardRef } from "react";

// Forwarding ref to access the modal DOM element
export const Model = forwardRef(({ onContinue }, ref) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <div
      className="modal fade"
      id="exampleModalToggle"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel"
      tabIndex="-1"
      ref={ref} 
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalToggleLabel">
              Disclaimer for Joining DMT Club Donation Program
            </h1>
          
          </div>
          <div className="modal-body">
         
            <p>1.The DMT Club membership requires a one-time payment of 30 USDT to activate the membership.</p>
            <p>2.Each level has a specific donation requirement, which must be completed in sequential order from Level 1 to Level 6.</p>
            <p>3.Upon joining, donations will be distributed across 6 levels based on the referrals, ensuring benefits for both the member and their network.</p>
            <p>4.The form should be completed with accurate wallet addresses, and all transactions must be made through the DMT Club platform.</p>
            <p>5.Upon successful completion of all six levels, the member will receive a permanent DMT Club membership, unlocking exclusive lifelong benefits.</p>

          </div>
          <div className="modal-footer">
            <div className="pt-1">
              <input
                type="checkbox"
                className=""
                onChange={handleCheckboxChange}
              />
            </div>
            <p>Accept disclaimer & Continue</p>
            <button
              className="btn btn-xxs btn-primary"
              disabled={!isChecked} // Disable until checked
              onClick={onContinue} // Invoke the passed function
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});
