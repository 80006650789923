import React from "react";
import logo from "../Asset 2.png";


import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div>
      <footer className="footer-area mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget mb-30">
                <a href="" className="footer-logo d-inline-block">
                  <img src={logo} alt="logo" />
                </a>
                <p>
                  Start your journey with the DMT Club by contributing to a
                  system designed for success. Your participation helps build a
                  sustainable financial future
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget mb-30 pl-3">
                <h3>Quick Links</h3>
                <ul className="quick-links-list">
                  <li>
                    <a href="">About Us</a>
                  </li>
                  <li>
                    <a href="">Comming Soon</a>
                  </li>

                  <li>
                    <a href="">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="">Terms &amp; Conditions</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget mb-30">
                <h3>Socail Media</h3>

                <ul className="social">
                  <li>
                    <a href="https://facebook.com/" target="_blank">
                      <i className="bx bxl-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/" target="_blank">
                      <i className="bx bxl-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="https://instagram.com/" target="_blank">
                      <i className="bx bxl-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/" target="_blank">
                      <i className="bx bxl-youtube" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-footer-widget mb-30">
                <h3>DMT Club</h3>
              
                <ul className="quick-links-list">
                  <li>
                    <Link to="/register">Register</Link>
                  </li>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>

                  <li>
                    <a href="">Plan</a>
                  </li>
                </ul>
                {/* <div className="footer-location">
                  <img src="assets/img/footer-map.png" alt="image" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="">
                <p>
                  Copyright 2024{" "}
                  <a href="" target="_blank">
                    Dmt club
                  </a>
                  . All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
