import React, { useState, useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userExistAction } from "../../store/actions/AuthActions";
import { getTimer, getMailData } from "../../helpers/api";
import {
  getBalanceUser,
  isUserExist,
  upgradeLevelMember,
  getUserData,
  upgardeSixLevel,
  getUserLevelStatus,
} from "../../helpers/getWeb3";
import { USDT_Token_ADDRESS } from "../../helpers/config";
import logo from "../../blacklogo.png";
import CountdownTimer from "./CountDownTimer.jsx"; // Import CountdownTimer
import { TiTick } from "react-icons/ti";
import { RxCrossCircled } from "react-icons/rx";
import toast from "react-hot-toast";
import ConnectButtons from "../components/Button/ConnectButton.jsx";
import { Loader } from "../components/Dashboard/Loader.jsx";
function LevelUpgrade() {
  const { walletAddress } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userId = userDetails?.userId;
  const [loader, setLoader] = useState(false);

  const [loading, setLoading] = useState(false);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [timerTimestamp, setTimerTimestamp] = useState(0);
  const [userData, setUserData] = useState([]);
  const [mailData, setMailData] = useState("");
  const navigate = useNavigate();


  useEffect(() => {
    async function fetchMailData() {
      let formData = { userId: userId };
      const res = await getMailData(formData);
      setMailData(res?.plan1);
      setLoader(false);
    }
    fetchMailData();
  }, [userId]);

  useEffect(() => {
    const fetchTimer = () => {
      let formData = { userId: mailData?.userId,packageId:1 };
      getTimer(formData)
        .then((res) => {
          setTimerTimestamp(res?.date || 0);
        })
        .catch((err) => {
          console.error("Error fetching timer:", err);
        });
    };

    fetchTimer(); // Initial call

    const interval = setInterval(fetchTimer, 10000); // Call every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [mailData]);

  useEffect(() => {
    async function fetchBalance() {
      const res = await getBalanceUser(walletAddress, USDT_Token_ADDRESS);
      const balance = Number(res?.value / 1e18) || 0;
      setTokenBalance(balance);
    }
    fetchBalance();
  }, [walletAddress]);



  useEffect(() => {
    
    async function fetchUserData() {

       const sixleveldata =  new Array(6).fill(0).map((_,i)=>{
          const res = getUserLevelStatus(walletAddress,1,i+1);
          return res;
        })
      const level6res = await Promise.all(sixleveldata);
      setUserData(level6res);
    }
    fetchUserData();
  }, [refresh, walletAddress]);
  async function registerApp(level, amount) {
    try {
      const exists = await isUserExist(walletAddress);
      
      if (!exists) {
        toast.error("Wallet does not exist!");
        return;
      }
      
      const walletAddressLower = walletAddress?.toLowerCase();
      const mailAddressLower = mailData?.wallet_address?.toLowerCase();
      
      if (walletAddressLower === mailAddressLower) {
        await upgradeLevelMember(
          walletAddress,
          amount,
          USDT_Token_ADDRESS,
          setLoading,
          level,
          1,
          ()=>{
            setRefresh(!refresh)
          }
        );
      } else {
        toast.error("Register wallet and current wallet address do not match!");
      }
    } catch (error) {
      console.error("An error occurred during registration:", error);
      toast.error("An unexpected error occurred. Please try again.");
    }
  }
  
  function registerAppSix(level, amount) {
    isUserExist(walletAddress)
      .then((exists) => {
        if (!exists) {
          toast.error("Wallet does not exist!");
          return;
        }
  
        // Log the wallet addresses for debugging
        console.log("walletAddress:", walletAddress);
        console.log("mailData.wallet_address:", mailData?.wallet_address);
  
        // Convert to lowercase for comparison
        const walletAddressLower = walletAddress?.toLowerCase();
        const mailAddressLower = mailData?.wallet_address?.toLowerCase();
  
        // Check if the provided wallet address matches
        if (walletAddressLower === mailAddressLower) {
          // Proceed with the level upgrade
          upgardeSixLevel(
            walletAddress,
            amount,
            USDT_Token_ADDRESS,
            setLoading,
            level,
            navigate,
            1,
            ()=>{
              setRefresh(!refresh)
            }
          );
        } else {
          toast.error("Register wallet and current wallet address do not match!");
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the promise execution
        console.error("An error occurred:", error);
        toast.error("An unexpected error occurred. Please try again.");
      });
  }
  useEffect(() => {
    let formData = {
      userId: userId,
    
    };
    getMailData(formData).then((res) => {

      if (res?.plan1?.email_verified && res?.plan1?.paid_30_dollar) {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        userDetails.memberregister = true;
        localStorage.setItem("userDetails", JSON.stringify(userDetails));
      }else if(res?.plan3?.email_verified && res?.plan2?.paid_30_dollar){
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        userDetails.memberregister = true;
        localStorage.setItem("userDetails", JSON.stringify(userDetails));

      }else if(res?.plan3?.email_verified && res?.plan3?.paid_30_dollar){
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        userDetails.memberregister = true;
        localStorage.setItem("userDetails", JSON.stringify(userDetails));
      }
    });
  }, [userId]);
  return (
    <div className="page_content gray_bg">
      <div className="container login_page_container">
        <div className="login_header-new">
          <div>
            <img src={logo} alt="" className="mCS_img_loaded" height={50} />
          </div>
          <div className="deal-of-the-day-content mb-3 mt-0 d-flex justify-content-center align-items-center">
            <CountdownTimer timestamp={timerTimestamp} />
          </div>
        </div>
        {loader ? (
          <Loader />
        ) : (
          <main className="login-page_wrapper">
          <div className="row my-5">
            <div className="col-lg-8 m-auto">
            <div className="login-card-new">
              <div className="d-flex justify-content-between">
                <h2 className="login_title mb-1">Upgrade Level</h2>
                <ConnectButtons />
              </div>
              <p className="text_gray">Upgrade all levels within 72 hours</p>
              <div className="d-flex justify-content-between">
                <div className="text-endtext-white fs-6">
                  Balance:{" "}
                  <span className="text-info">
                    {tokenBalance?.toFixed(3)} USDT
                  </span>
                </div>
                <div className="text-endtext-white fs-6">
                  Referal Id:{" "}
                  <span className="text-info">{mailData?.referrerId} </span>
                </div>
              </div>
              <div className="text-endtext-white pb-2 fs-6">
                Register Wallet:{" "}
                <span className="text-warning">
                  {mailData?.wallet_address?.slice(0, 10)}...
                  {mailData?.wallet_address?.slice(-10)}{" "}
                </span>
              </div>
              <div className="cart-table table-responsive">
                <table className="table table-bordered custom-table">
                  <thead>
                    <tr>
                      <th>Level</th>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Status</th>
                      <th>Activate</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>6</td>
                      <td>Universe DMT</td>
                      <td>$200</td>
                      <td>
                        {userData[5]? (
                          <TiTick color="green" size={20} />
                        ) : (
                          <RxCrossCircled color="red" size={20} />
                        )}
                      </td>
                      <td>
                        {userData[5]? (
                          <button
                            style={{ cursor: "not-allowed" }}
                            className="btn btn-success btn-xxs"
                          >
                            Donated
                          </button>
                        ) : userData[0] &&
                          userData[1] &&
                          userData[2] &&
                          userData[3] &&
                          userData[4] &&
                          !userData[5]? (
                          <button
                            className="btn btn-warning btn-xxs"
                            onClick={() => registerAppSix(6, 200)}
                          >
                            {loading ? "Loading" : "Donate Now"}
                          </button>
                        ) : (
                          <button
                            className="btn btn-warning btn-xxs"
                            style={{ opacity: 0.2 }}
                          >
                            Donate Now
                          </button>
                        )}{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Supernova DMT</td>
                      <td>$50 </td>
                      <td>
                        {userData[4] ? (
                          <TiTick color="green" size={20} />
                        ) : (
                          <RxCrossCircled color="red" size={20} />
                        )}
                      </td>
                      <td>
                        {userData[4] ? (
                          <button
                            style={{ cursor: "not-allowed" }}
                            className="btn btn-success btn-xxs"
                          >
                            Donated
                          </button>
                        ) : userData[0] &&
                          userData[1] &&
                          userData[2] &&
                          userData[3] &&
                          !userData[4] &&
                          !userData[5]? (
                          <button
                            className="btn btn-warning btn-xxs"
                            onClick={() => registerApp(5, 50)}
                          >
                            {loading ? "Loading" : "Donate Now"}
                          </button>
                        ) : (
                          <button
                            className="btn btn-warning btn-xxs"
                            style={{ opacity: 0.2 }}
                          >
                            Donate Now
                          </button>
                        )}{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Galaxy DMT</td>
                      <td>$40</td>
                      <td>
                        {userData[3] ? (
                          <TiTick color="green" size={20} />
                        ) : (
                          <RxCrossCircled color="red" size={20} />
                        )}
                      </td>
                      <td>
                        <td>
                          {userData[3] ? (
                            <button
                              style={{ cursor: "not-allowed" }}
                              className="btn btn-success btn-xxs"
                            >
                              Donated
                            </button>
                          ) : userData[0] &&
                            userData[1] &&
                            userData[2] &&
                            !userData[3] &&
                            !userData[4] &&
                            !userData[5]? (
                            <button
                              className="btn btn-warning btn-xxs"
                              onClick={() => registerApp(4, 40)}
                            >
                              {loading ? "Loading" : "Donate Now"}
                            </button>
                          ) : (
                            <button
                              className="btn btn-warning btn-xxs"
                              style={{ opacity: 0.2 }}
                            >
                              Donate Now
                            </button>
                          )}{" "}
                        </td>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Solar DMT</td>
                      <td>$30</td>
                      <td>
                        {" "}
                        {userData[2] ? (
                          <TiTick color="green" size={20} />
                        ) : (
                          <RxCrossCircled color="red" size={20} />
                        )}{" "}
                      </td>
                      <td>
                        {userData[2] ? (
                          <button
                            style={{ cursor: "not-allowed" }}
                            className="btn btn-success btn-xxs"
                          >
                            Donated
                          </button>
                        ) : userData[0] &&
                          userData[1] &&
                          !userData[2] &&
                          !userData[3] &&
                          !userData[4] &&
                          !userData[5]? (
                          <button
                            className="btn btn-warning btn-xxs"
                            onClick={() => registerApp(3, 30)}
                          >
                            {loading ? "Loading" : "Donate Now"}
                          </button>
                        ) : (
                          <button
                            className="btn btn-warning btn-xxs"
                            style={{ opacity: 0.2 }}
                          >
                            Donate Now
                          </button>
                        )}{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Planet DMT</td>
                      <td>$20</td>
                      <td>
                        {userData[1] ? (
                          <TiTick color="green" size={20} />
                        ) : (
                          <RxCrossCircled color="red" size={20} />
                        )}
                      </td>
                      <td>
                        {userData[1] ? (
                          <button
                            style={{ cursor: "not-allowed" }}
                            className="btn btn-success btn-xxs"
                          >
                            Donated
                          </button>
                        ) : userData[0] &&
                          !userData[1] &&
                          !userData[2] &&
                          !userData[3] &&
                          !userData[4] &&
                          !userData[5]? (
                          <button
                            className="btn btn-warning btn-xxs"
                            onClick={() => registerApp(2, 20)}
                          >
                            {loading ? "Loading" : "Donate Now"}
                          </button>
                        ) : (
                          <button
                            className="btn btn-warning btn-xxs"
                            style={{ opacity: 0.2 }}
                          >
                            Donate Now
                          </button>
                        )}{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Star DMT</td>
                      <td>$130</td>
                      <td>
                        {userData[0] ? (
                          <TiTick color="green" size={20} />
                        ) : (
                          <RxCrossCircled color="red" size={20} />
                        )}
                      </td>
                      <td>
                        {userData[0] ? (
                          <button
                            style={{ cursor: "not-allowed" }}
                            className="btn btn-success btn-xxs"
                          >
                            Donated
                          </button>
                        ) : !userData[0] &&
                          !userData[1] &&
                          !userData[2] &&
                          !userData[3] &&
                          !userData[4] &&
                          !userData[5]? (
                          <button
                            className="btn btn-warning btn-xxs"
                            onClick={() => registerApp(1, 130)}
                          >
                            {loading ? "Loading" : "Donate Now"}
                          </button>
                        ) : (
                          <button
                            className="btn btn-warning btn-xxs"
                            style={{ opacity: 0.2 }}
                          >
                            Donate Now
                          </button>
                        )}{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>
            </div>
          </main>
        )}
      </div>
    </div>
  );
}

export default LevelUpgrade;
