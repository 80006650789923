import React, { useEffect } from "react";
import Header from "./Home/Header";
import Marquee from "react-fast-marquee";
import Footer from "./Home/Footer";
import { About } from "./Home/About";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import { Locked } from "./Home/Locked";
import video from "./output.mp4";
import file3 from "./json/usdt-coin.json";
import file2 from "./json/18.json";

import video1 from "./images/browser/11.mp4";

import { AbouUs } from "./Home/AbouUs";
import { Banner } from "./Home/Banner";
import { OverView } from "./Home/OverView";

const HomeMain = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };
  useEffect(() => {
    // Check if userDetails is already in localStorage
    const existingDetails = localStorage.getItem("userDetails");
    if (!existingDetails) {
      // If not, set the default values
      localStorage.setItem(
        "userDetails",
        JSON.stringify({
          email: "",

          memberregister: "",
          userId: "",
        })
      );
    }
  }, []); // Empty dependency a
  return (
    <>
      <Header />
      <Banner />

      <div className="home-banner ">
        <AbouUs />

        <div className="container video">
          <div className="card">
            <div className="card-body">
              <div className="ratio ratio-21x9 mb-5">
                <iframe
                  width="833"
                  height="820"
                  src="https://www.youtube.com/embed/VP525rw7tHA??autoplay=1"
                  frameborder="0"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <section className="chain-list-section">
          <p className="chain-list-gradient-para">
            Developing on-chain privacy infrastructure
          </p>
          <h2 className="chainlist-heading">Safeguard your on-chain data</h2>
          <p className="chainlist-para">
            Dmt Club aims to enhance digital asset management by providing
            enhanced privacy, security, and efficiency.
          </p>
          <div className="container">
            <div className="service-section">
              <div className="row">
                <div className="col-12 col-sm-6 col-md-4 mb-4">
                  <div className="card-ant-card ant-card-bordered">
                    <div className="card-body ant-card-body">
                      <div className="image-container mt-3 mb-3">
                        <img src="./p2p.png" alt="Logo" />
                      </div>
                      <h5 className="ant-card1-heading">P2P Helping</h5>
                      <p className="ant-card-para">
                        DMT Club's P2P Helping Plan fosters a supportive
                        community where members exchange skills and assistance
                        directly. Experience personalized help, boost your
                        knowledge, and build valuable connections.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-6 col-md-4 mb-4">
                  <div className="card-ant-card ant-card-bordered">
                    <div className="card-body ant-card-body">
                      <div className="image-container mt-3 mb-3">
                        <img src="./invite (2).png" alt="Logo" />
                      </div>
                      <h5 className="ant-card1-heading">Invite to Earn</h5>
                      <p className="ant-card-para">
                        Join our DMT Club's "Invite to Earn" program! Invite
                        friends to our P2P Helping platform and earn rewards for
                        each successful referral. Expand your network, gain
                        exclusive benefits, and contribute to a thriving
                        community. .
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 mb-4">
                  <div className="card-ant-card ant-card-bordered">
                    <div className="card-body ant-card-body">
                      <div className="image-container mt-3 mb-3">
                        <img src="./CLUB.png" alt="Logo" />
                      </div>
                      <h5 className="ant-card1-heading">Why DMT Club ?</h5>
                      <p className="ant-card-para">
                        Investing in digital currency with cash is safe,
                        discreet, and accessible, offering decentralized finance
                        benefits while maintaining asset ownership. Prioritize
                        research, use trustworthy platforms, and ensure safety.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <About />

        <div className="pt-3 pb-3">
          <div className="container">
            <section className="chain-list-section">
              <div className="section-title">
                <h2>Core Technology.</h2>
              </div>
              <div className="container">
                <div className="service-section">
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 mb-2">
                      <div className="card-body ant-card-body">
                        <div className="media-container mt-3 mb-3">
                          <Lottie
                            animationData={file3}
                            loop={true}
                            style={{ height: "200px" }}
                          />
                        </div>
                        <h5 className="ant-card-heading ant-card-heading">
                          Stable Gift
                        </h5>
                        <p className="ant-card-para-new">
                          Easily gift USDT, offering stability and full control.
                        </p>
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-2">
                      <div className="card-body ant-card-body">
                        <div className="media-container mt-3 mb-3">
                          <video
                            src={video}
                            loop
                            autoPlay
                            muted
                            style={{ height: "200px", width: "100%" }}
                          ></video>
                        </div>
                        <h5 className="ant-card-heading">Gift Ratio</h5>
                        <p className="ant-card-para-new">
                          Allocate USDT gifts with customizable ratios.
                        </p>
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-2">
                      <div className="card-body ant-card-body">
                        <div className="media-container mt-3 mb-3">
                          <Lottie
                            animationData={file2}
                            loop={true}
                            style={{ height: "200px" }}
                          />
                        </div>
                        <h5 className="ant-card-heading">Gift Lock</h5>
                        <p className="ant-card-para-new">
                          Lock gifts for timed access and added control.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="section-title">
                <h2>Building On</h2>
              </div>
              <div className="waleet-section">
                <Marquee speed={13}>
                  {" "}
                  {/* speed set to 13 and gradient turned off for a cleaner look */}
                  <div className="d-flex align-items-center">
                    <div className="p-2">
                      <img
                        src="https://www.safepal.com/assets/img/logo-light.svg"
                        alt="SafePal"
                        style={{ height: "40px" }}
                        className="silde-image"
                      />
                    </div>
                    <div className="p-2">
                      <img
                        src="./logo-metamask (2).png"
                        alt="MetaMask"
                        style={{ height: "40px" }}
                        className="silde-image"
                      />
                    </div>
                    <div className="p-2">
                      <img
                        src="./logo-dim.svg"
                        alt="Dim"
                        style={{ height: "40px" }}
                        className="silde-image"
                      />
                    </div>
                    <div className="p-2">
                      <img
                        src="./tokenpoc.png"
                        alt="TokenPOC"
                        style={{ height: "40px" }}
                        className="silde-image"
                      />
                    </div>
                    {/* Duplicate the items for continuous scrolling */}
                    <div className="p-2">
                      <img
                        src="https://www.safepal.com/assets/img/logo-light.svg"
                        alt="SafePal"
                        style={{ height: "40px" }}
                        className="silde-image"
                      />
                    </div>
                    <div className="p-2">
                      <img
                        src="./logo-metamask (2).png"
                        alt="MetaMask"
                        style={{ height: "40px" }}
                        className="silde-image"
                      />
                    </div>
                    <div className="p-2">
                      <img
                        src="./logo-dim.svg"
                        alt="Dim"
                        style={{ height: "40px" }}
                        className="silde-image"
                      />
                    </div>
                    <div className="p-2">
                      <img
                        src="./tokenpoc.png"
                        alt="TokenPOC"
                        style={{ height: "40px" }}
                        className="silde-image"
                      />
                    </div>
                  </div>
                </Marquee>
              </div>
            </section>
          </div>
        </div>
        <Locked />
        <OverView />
        <div className="benefits-section container text-center text-white">
          <div className="section-title">
            <h2>Comming Soon.</h2>
            <p>
              Bhash Token: A New Era in Crypto Transactions - Launching Soon!
            </p>
          </div>
          <section className="row spacer-lg pt-0 ">
            <div className="col-lg-6 col-md-6 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="ratio ratio-1x1">
                    <video src={video1} loop autoPlay muted></video>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mb-3">
              <p>
                We are thrilled to announce that the DMT Club is on the verge of
                launching its very own cryptocurrency— BHASH Token (Blockhash).
                This marks a significant milestone in our journey as we
                transition from utilizing stable tokens like USDT to introducing
                our proprietary token for all transactions within the platform.
              </p>
              <p>
                Currently, all our transactions are executed in USDT, a
                stablecoin known for its consistent value. However, holding USDT
                provides minimal returns over time. In contrast, the BHASH Token
                is designed to offer substantial growth potential. By holding
                BHASH Tokens in your wallet, not only can you expect their value
                to appreciate daily, but you also stand to gain significant
                profits in the long run.
              </p>
              <h1 className="chain-list-gradient-para">
                The Vision for BHASH Token
              </h1>
              <p>
                The introduction of the BHASH Token is set to revolutionize how
                we handle transactions within the DMT Club. Unlike USDT, the
                BHASH Token is designed with the potential for significant value
                appreciation. As more members join the DMT Club and more
                transactions are made using BHASH Tokens, the demand for our
                token will naturally increase, driving up its value over time.
              </p>
              <a href="Bhash" className="default-btn">
                <i className="bx bx-food-menu icon-arrow before" />
                <span className="label">Read More</span>
                <i className="bx bx-food-menu icon-arrow after" />
              </a>
            </div>
          </section>
        </div>
      </div>

      <section className="spacer-lg container pt-5">
        <div className="row">
          <div className="col-lg-12 mb-5  text-center">
            <div className="section-title">
              <h2> Frequently Asked questions</h2>
              <p>
                Hey there! Got questions? We've got answers. Check out our FAQ
                page for all the deets. Still not satisfied? Hit us up.
              </p>
            </div>
          </div>

          <div className="accordion accordion_wrapper" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  1. How do I qualify for the initial enrollment target in the
                  DMT Club?
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <p>
                    To qualify for the initial enrollment target, you need to
                    recruit 4 new members directly under you within the first 15
                    days of joining the DMT Club. Each of these 4 members must
                    then recruit 4 new members each, resulting in a total of 20
                    members (4 direct recruits and 16 second-level recruits)
                    within the 15-day period. Achieving this target qualifies
                    you for the monthly reward plan.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  2. What are the monthly reward criteria, and how are the
                  rewards distributed?
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <p>
                    To earn monthly rewards, you and your downline members must
                    meet specific targets within 30 days for each level. The
                    targets and corresponding rewards are as follows:
                    <ul>
                      <li>
                        {" "}
                        3rd Level: Recruit 12 new members to the downline tree -
                        200 USDT
                      </li>
                      <li>
                        4th Level: Recruit 32 new members to the downline tree -
                        500 USDT
                      </li>
                      <li>
                        5th Level: Recruit 100 new members to the downline tree
                        - 2000 USDT
                      </li>
                      <li>
                        6th Level: Recruit 300 new members to the downline tree
                        - 5000 USDT
                      </li>
                    </ul>
                    Rewards are distributed monthly to your wallet from the DMT
                    Club, provided all parts (A, B, C, D) of your downline meet
                    their joining targets.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  3. What happens if my downline does not meet the monthly
                  targets?
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <p>
                    If any part (A, B, C, D) of your downline fails to meet the
                    specific joining targets for a given month, you will not
                    qualify for that month's reward. You will need to try again
                    the following month to meet the targets and qualify for the
                    rewards. Continuous collaboration and support within your
                    team are crucial to achieving these targets.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  4. How is the detailed target breakdown structured for each
                  level?
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <p>
                    {" "}
                    The detailed target breakdown for each level is as follows:
                  </p>

                  <ul>
                    <li>
                      3rd Level: Divide 64 members into 4 parts (A, B, C, D)
                      with 16 members each. Each part must recruit 16 members,
                      with at least 3 members from each part joining, totaling
                      12 members across the 4 parts.
                    </li>
                    <li>
                      4th Level: Divide 256 members into 4 parts (A, B, C, D)
                      with 64 members each. Each part must recruit 64 members,
                      with at least 8 members from each part joining, totaling
                      32 members across the 4 parts.
                    </li>
                    <li>
                      5th Level: Divide 1024 members into 4 parts (A, B, C, D)
                      with 256 members each. Each part must recruit 256 members,
                      with at least 25 members from each part joining, totaling
                      100 members across the 4 parts.
                    </li>
                    <li>
                      6th Level: Divide 4096 members into 4 parts (A, B, C, D)
                      with 1024 members each. Each part must recruit 1024
                      members, with at least 75 members from each part joining,
                      totaling 300 members across the 4 parts.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#headingFive"
                  aria-expanded="false"
                  aria-controls="headingFive"
                >
                  5. What are the benefits of achieving lifetime membership in
                  the DMT Club?
                </button>
              </h2>
              <div
                id="headingFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <p>
                    Upon completing all levels and meeting the required member
                    targets at each stage, you become a permanent member of the
                    DMT Club. As a permanent member, you will receive a share of
                    the total USDT collected from new memberships. Specifically,
                    10% of club joining fees are distributed equally among all
                    permanent members every month, providing you with a lasting
                    and substantial monthly income.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default HomeMain;
