import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import logo from "../../blacklogo.png";
import backTop from "../../images/browser/login_bg1.png";
import backbottom from "../../images/browser/flower.png";
import { getUserDetails, guestRegister } from "../../helpers/api";
import { LuHome } from "react-icons/lu";
import { FcGoogle } from "react-icons/fc";

function GuestLogin() {
  const route = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [referralId, setReferralId] = useState("DMT123456");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  function validateForm() {
    let isValid = true;
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError("");
    }
    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
      isValid = false;
    } else {
      setPasswordError("");
    }
    return isValid;
  }

  function registerApp() {
    if (validateForm()) {
      if (!referralId) {
        setLoading(false);

        toast.error("Enter Sponsor ID");
        return;
      }
      setLoading(true);

      let formData = {
        email: email,
        password: password,
        referralId: referralId,
      };
      guestRegister(formData)
        .then((res) => {
          console.log(res, "res::userId");
          if (res?.status && res?.message != "Your Id registered Successfully and Email Verified") {
            toast.success(res?.message);
            navigate("/otp", {
              state: { email: email, userId: res?.data },
            }); // Pass email in state
            setLoading(false);
          } else if (res?.status && res?.message == "Your Id registered Successfully and Email Verified") {
            toast.success(res?.message);
            navigate("/logindashboard"); // Pass email in state
            localStorage.setItem(
              "userDetails",
              JSON.stringify({
                email: email,

                userId: res?.data,
              })
            );
            setLoading(false);
          } else {
            toast.error(res?.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Registration failed",
            text: err.message,
          });
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    if (route.search) {
      const code =
        route?.search.indexOf("user_id=") > -1
          ? route?.search?.split("user_id=")[1]
          : "";
      if (code) setReferralId(code);
    }
  }, [route]);

  return (
    <>
      <div className="page_content gray_bg">
        <div className="container login_page_container">
          <div className="login_header">
            <div>
              <Link to="/">
                <img
                  src={logo}
                  alt="logo"
                  className="mCS_img_loaded"
                  height={50}
                />
              </Link>
            </div>
            <div className="d-flex gap-3">
              <Link to="/">
                <div className="short_icon_outline">
                  <LuHome />
                </div>
              </Link>
            </div>
          </div>

          <main className="page_wrapper">
            <div className="side_img1">
              <img src={backTop} alt="Background Top" />
            </div>
            <div className="login-card">
              <h2 className="login_title mb-1">Register Here</h2>
              <p className="text_gray">Please login or sign up to continue</p>

              <div className="input-icon">
                <label className="text-white">Referral Id*</label>
                <input className="form-control" value={referralId} readOnly />
              </div>
              <div className="input-icon">
                <label className="text-white">Email*</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Your Email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
                {emailError && <p className="text-danger">{emailError}</p>}
              </div>
              <div className="input-icon">
                <label className="text-white">Password*</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form-control"
                  placeholder="Your Password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
                {passwordError && (
                  <p className="text-danger">{passwordError}</p>
                )}
              </div>

              <div className="d-grid mb-1 mt-5 text-center">
                <div
                  className="default-btn banner-btn"
                  onClick={!loading ? registerApp : null} // Disable button click while loading
                >
                  {loading ? (
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Register Here"
                  )}
                </div>
              </div>
              {/* <p className="text-center">or</p>
              <div className="d-grid">
                <div className="custom_btn_outline rounded_sm align-items-center d-flex justify-content-center">
                  <div>
                    <FcGoogle className="fs-5 me-1" />
                  </div>
                  <div>Google</div>
                </div>
              </div> */}
              <div className="text-center text_gray fs-12 py-3">
                Already have an account?{" "}
                <Link className="anchor_link" to="/login">
                  Login Here
                </Link>
              </div>
            </div>
            <div className="side_img2">
              <img src={backbottom} alt="Background Bottom" />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default GuestLogin;
