import { LuBoxes } from "react-icons/lu";
import { BiMoneyWithdraw, BiStreetView } from "react-icons/bi";
import { VscSymbolConstant } from "react-icons/vsc";
import { GrGroup } from "react-icons/gr";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { FaStaylinked, FaTicketAlt } from "react-icons/fa";
import { FcOnlineSupport } from "react-icons/fc";
export const MenuList = [
 
  

  // {
  //   title: "My Directs",
  //   classsChange: "mm-collapse",
  //   iconStyle: <AiOutlineUsergroupAdd />,
  //   to: "/partner",
  // },
  {
    title: "Level Report ",
    classsChange: "mm-collapse",
    iconStyle: <AiOutlineUsergroupAdd />,
    to: "/levelReport",
  },
  
 

  {
    title: "Tree Structure",
    classsChange: "mm-collapse",
    iconStyle: <FaStaylinked size={30} color="#fff" />,
    to: "/treeStructure",
  },
   
  {
    title: "Level Bonus",
    classsChange: "mm-collapse",
    iconStyle: <i className="material-icons">trending_up</i>,

    to: "/Level",
  },
  
 
  {
    title: "Reward Bonus",
    classsChange: "mm-collapse",
    iconStyle: <VscSymbolConstant size={30} color="#fff" />,
    to: "/reward",
  },
  {
    title: "Support →",
    classsChange: "mm-collapse",
    iconStyle: <FcOnlineSupport  />,
    to: "/help",
  },

];
