import React, { useContext, useEffect } from "react";
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import "./index.css";
import "./chart.css";
import "./step.css";
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
import Home from "./components/Dashboard/Home";
import Error404 from "./pages/Error404";
import { ThemeContext } from "../context/ThemeContext";
import Partners from "./components/Pages/Partners";
import LevelBonus from "./components/Pages/LevelBonus";
import TreeStructure from "./components/Pages/TreeStructure";
import MyTeam from "./components/Pages/MyTeam";
import RewardIncome from "./components/Pages/RewardIncome";
import LevelWise from "./components/Pages/LevelWiseData";

const Markup = () => {
  const allroutes = [
    { url: "", component: <Home /> },
    { url: "/dashboard", component: <Home /> },
    { url: "/Partner", component: <Partners /> },
    { url: "/Level", component: <LevelBonus /> },
    { url: "/treeStructure", component: <TreeStructure /> },
    { url: "/myteam", component: <MyTeam /> },
    { url: "/reward", component: <RewardIncome /> },
    { url: "/levelReport", component: <LevelWise /> },
  ];

  return (
    <>
      <Routes>
        <Route path="*" element={<Error404 />} />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route key={i} path={data.url} element={data.component} />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle, setMenuToggle } = useContext(ThemeContext);
  const location = useLocation();

  // Close sidebar when route changes
  useEffect(() => {
    if (menuToggle) {
      setMenuToggle(false); // Close sidebar on route change
    }
  }, [location, setMenuToggle]); // Trigger on location change

  return (
    <>
      <div className="home_page">
        <div
          id="main-wrapper"
          className={`show ${menuToggle ? "menu-toggle" : ""}`}
        >
          <Nav />
          <div className="content-body" style={{ minHeight: "25px" }}>
            <div className="container-fluid">
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Markup;
