// import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
// import "./tree.css";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { getUserDetailsByWallet, getTreeView } from "../../../helpers/api";
// import { Loader } from "../Dashboard/Loader";
// import logo from "../../../CLUB.png";
// import moment from "moment";

// const TreeStructure = () => {
//     const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
//     const walletAddress = walletDetails?.wallet;
//     const [apidata, setApidata] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [steps, setSteps] = useState([walletAddress]);

//     async function getLevelData(address, ar = []) {
//         const treedata = {
//             name: "",
//             children: [],
//         };

//         try {
//             const apidata = await getUserDetailsByWallet(address);

//             treedata.dateofJoining = apidata?.userDetails?.createdAt;
//             treedata.totalTeam = apidata?.userDetails?.team_count;
//             treedata.totalTeamBusiness = apidata?.userDetails?.staketeambusiness;
//             treedata.direct = apidata?.userDetails?.directCount;
//             treedata.directBusiness = apidata?.userDetails?.directbusiness;
//             treedata.teamBusiness = apidata?.userDetails?.staketeambusiness;
//             treedata.id = apidata?.userDetails?.userId;

//             const treeData = await getTreeView(address);

//             if (treeData?.length > 0) {
//                 treedata.children = treeData.map((item) => ({
//                     name: item?.userId.toString(),
//                     children: [],
//                     ...item,
//                 }));
//             }

//             setApidata([treedata]);
//             setLoading(true);
//             if (ar.length > 0) {
//                 setSteps(ar);
//             }
//         } catch (e) {
//             console.error("Error fetching data", e);
//             setLoading(true);
//             setApidata([treedata]);
//         }
//     }

//     useEffect(() => {
//         getLevelData(walletAddress, [walletAddress]);
//     }, [walletAddress]);

//     const tooltip = (chl) => (

//         <Tooltip id="multi-api-tooltip">
//             <strong>User_id:</strong> {chl?.userId} <br />
//             <strong>Date of Joining:</strong>{" "}
//             {moment(chl?.joining).format("DD-MM-YYYY")} <br />
//             <strong>Total Directs:</strong> {chl?.directCount} <br />
//             <strong>Total Team:</strong> {chl?.team_count} <br />
//             <strong>Direct Business:</strong> $ {chl?.directbusiness} <br />
//             <strong>Total Team Business:</strong> $ {chl?.staketeambusiness} <br />


//         </Tooltip>

//     );

//     const tooltipMain = (item) => (
//         <Tooltip id="multi-api-tooltip">
//             <strong>User_id:</strong> {item?.id} <br />
//             <strong>Date of Joining:</strong>

//             {moment(item?.dateofJoining).format("DD-MM-YYYY")} <br />
//             <strong>Total Team:</strong> {item?.totalTeam} <br />
//             <strong>Total Directs:</strong> {item?.direct} <br />
//             <strong>Direct Business:</strong> $ {item?.directBusiness} <br />
//             <strong>Team Business:</strong> $ {item?.teamBusiness} <br />
//         </Tooltip>
//     );

//     return (
//         <div className="card">
//             <div className="card-body">
//                 <section className="pb-5" style={{ height: "100%" }}>
//                     {!loading ? (
//                         <Loader />
//                     ) : (
//                         <div className="container">
//                             <h3 className="my-3 " style={{ textAlign: "center" }}>
//                                 Your Level Tree
//                             </h3>
//                             <div className="row">
//                                 <button
//                                     className="btn userTree"
//                                     style={{
//                                         border: "1px solid #282828",
//                                         width: "max-content",
//                                     }}
//                                     onClick={() => {
//                                         let ar = [...steps];
//                                         if (ar.length > 1) {
//                                             ar.pop();
//                                             getLevelData(ar[ar.length - 1], ar);
//                                         } else {
//                                             setSteps([walletAddress]);
//                                             getLevelData(walletAddress);
//                                         }
//                                     }}
//                                 >
//                                     {"< Go back"}
//                                 </button>
//                                 <button
//                                     className="btn userTree mx-1 "
//                                     style={{
//                                         border: "1px solid #282828",
//                                         width: "max-content",
//                                     }}
//                                     onClick={() => {
//                                         getLevelData(walletAddress, [walletAddress]);
//                                     }}
//                                 >
//                                     {"Root"}
//                                 </button>
//                                 <div className="tree col-lg-12 col-md-12 mx-auto treresp">
//                                     {apidata?.length > 0 &&
//                                         apidata?.map((item) => (
//                                             <ul
//                                                 style={{
//                                                     display: "flex",
//                                                     justifyContent: "center",
//                                                 }}
//                                                 key={item.id}
//                                             >
//                                                 <li>
//                                                     <OverlayTrigger
//                                                         placement="top"
//                                                         overlay={tooltipMain(item)}
//                                                     >
//                                                         <a
//                                                             href="#"
//                                                             className="custom-style"
//                                                             style={{ width: "100px", height: "100px", border: '0.3px solid rgb(182 182 182)' }}
//                                                         >
//                                                             <div>
//                                                                 <img src={logo} alt="" width={50} />
//                                                                 <p className="text-center  pt-1" style={{ fontSize: '14px' }}>
//                                                                     {item?.id}
//                                                                 </p>
//                                                             </div>
//                                                         </a>
//                                                     </OverlayTrigger>
//                                                     <ul className="subtree table-responsive pb-3">
//                                                         {item?.children.map((chl) => (
//                                                             <OverlayTrigger
//                                                                 placement="top"
//                                                                 overlay={tooltip(chl)}
//                                                                 key={chl?.userId}
//                                                             >
//                                                                 <li
//                                                                     onClick={() => {
//                                                                         const ar = [...steps, chl.user];
//                                                                         getLevelData(chl.user, ar);
//                                                                     }}
//                                                                 >
//                                                                     <a
//                                                                         href="#"
//                                                                         style={{ width: "100px", height: "100px" }}
//                                                                     >
//                                                                         <div className="p-0">
//                                                                             <img
//                                                                                 src={logo}
//                                                                                 alt=""
//                                                                                 width={50}
//                                                                             />
//                                                                             <p className="text-center  pt-1" style={{ fontSize: '14px' }}>
//                                                                                 {chl?.userId}
//                                                                             </p>
//                                                                         </div>
//                                                                     </a>
//                                                                 </li>
//                                                             </OverlayTrigger>
//                                                         ))}
//                                                     </ul>
//                                                 </li>
//                                             </ul>
//                                         ))}
//                                 </div>

//                             </div>
//                         </div>
//                     )}
//                 </section>
//             </div>
//         </div>
//     );
// };

// export default TreeStructure;
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./tree.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getUserDetailsByWallet, getTreeView } from "../../../helpers/api";
import { Loader } from "../Dashboard/Loader";
import logo from "../../../dmt1.png";
import moment from "moment";

const TreeStructure = () => {
    const { userToken } = useSelector((action) => {
        return action.auth;
    });
    const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
    const walletAddress = walletDetails?.wallet;
    const [apidata, setApidata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [steps, setSteps] = useState([walletAddress]);

    async function getLevelData(address, ar = [], packageId) {
        const treedata = {
            name: "",
            children: [],
        };

        try {
            const apidata = await getUserDetailsByWallet(address,Number(packageId));

            treedata.dateofJoining = apidata?.userDetails?.createdAt;
         
            treedata.direct = apidata?.userDetails?.direct_member;
     
         
            treedata.id = apidata?.userDetails?.userId;

            const treeData = await getTreeView(address, packageId);

            if (treeData?.length > 0) {
                treedata.children = await Promise.all(treeData.map(async (item) => {
                    const childData = await getUserDetailsByWallet(item?.user,packageId);
                    return {
                        name: item?.userId.toString(),
                        children: [],
                        ...item,
                        dateofJoining: childData?.userDetails?.createdAt,
                
                        direct: childData?.userDetails?.direct_member,
                    
                    };
                }));
            }

            setApidata([treedata]);
            setLoading(true);
            if (ar.length > 0) {
                setSteps(ar);
            }
        } catch (e) {
            console.error("Error fetching data", e);
            setLoading(true);
            setApidata([treedata]);
        }
    }

    useEffect(() => {
        getLevelData(walletAddress, [walletAddress], userToken?.value);
    }, [walletAddress, userToken]);

    const tooltip = (chl) => (
        <Tooltip id="multi-api-tooltip">
            <strong>User_id:</strong> {chl?.userId} <br />
            <strong>Date of Joining:</strong>{" "}
            {moment(chl?.dateofJoining).format("DD-MM-YYYY")} <br />
            <strong>Total Directs:</strong> {chl?.direct} <br />
        
        </Tooltip>
    );

    const tooltipMain = (item) => (
        <Tooltip id="multi-api-tooltip">
            <strong>User_id:</strong> {item?.id} <br />
            <strong>Date of Joining:</strong>
            {moment(item?.dateofJoining).format("DD-MM-YYYY")} <br />
        
            <strong>Total Directs:</strong> {item?.direct} <br />

        </Tooltip>
    );

    return (
        <div className="card">
            <div className="card-body">
                <section className="pb-5" style={{ height: "100%" }}>
                    {!loading ? (
                        <Loader />
                    ) : (
                        <div className="container">
                            <h3 className="my-3 " style={{ textAlign: "center" }}>
                                Your Level Tree
                            </h3>
                            <div className="row">
                                <button
                                    className="btn userTree"
                                    style={{
                                        border: "1px solid #282828",
                                        width: "max-content",
                                    }}
                                    onClick={() => {
                                        let ar = [...steps];
                                        if (ar.length > 1) {
                                            ar.pop();
                                            getLevelData(ar[ar.length - 1], ar);
                                        } else {
                                            setSteps([walletAddress]);
                                            getLevelData(walletAddress, [walletAddress],userToken?.value);
                                        }
                                    }}
                                >
                                    {"< Go back"}
                                </button>
                                <button
                                    className="btn userTree mx-1 "
                                    style={{
                                        border: "1px solid #282828",
                                        width: "max-content",
                                    }}
                                    onClick={() => {
                                        getLevelData(walletAddress, [walletAddress],userToken?.value);
                                    }}
                                >
                                    {"Root"}
                                </button>
                                <div className="tree col-lg-12 col-md-12 mx-auto treresp">
                                    {apidata?.length > 0 &&
                                        apidata.map((item) => (
                                            <ul
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                                key={item.id}
                                            >
                                                <li>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={tooltipMain(item)}
                                                    >
                                                        <a
                                                            href="#"
                                                            className="custom-style"
                                                            style={{ width: "100px", height: "100px", border: '0.3px solid rgb(182 182 182)' }}
                                                        >
                                                            <div>
                                                                <img src={logo} alt="" width={50} />
                                                                <p className="text-center  pt-1" style={{ fontSize: '14px' }}>
                                                                    {item?.id}
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </OverlayTrigger>
                                                    <ul className="subtree table-responsive pb-3">
                                                        {item?.children.map((chl) => (
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={tooltip(chl)}
                                                                key={chl?.userId}
                                                            >

                                                              
                                                                <li
                                                                    onClick={() => {
                                                                        const ar = [...steps, chl.userId];
                                                                        getLevelData(chl.user, ar,userToken?.value);
                                                                    }}
                                                                >
                                                                    <a
                                                                        href="#"
                                                                        style={{ width: "100px", height: "100px" ,border: '0.3px solid rgb(182 182 182)'}}
                                                                    >
                                                                        <div className="p-0">
                                                                            <img
                                                                                src={logo}
                                                                                alt=""
                                                                                width={50}
                                                                            />
                                                                            <p className="text-center  pt-1" style={{ fontSize: '14px' }}>
                                                                                {chl?.userId}
                                                                            </p>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            </OverlayTrigger>
                                                        ))}
                                                    </ul>
                                                </li>
                                            </ul>
                                        ))}
                                </div>
                            </div>
                        </div>
                    )}
                </section>
            </div>
        </div>
    );
};

export default TreeStructure;
